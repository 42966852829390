import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, HStack, Icon, Spinner, Flex, IconButton, Fab, FlatList, Text, Pressable, VStack, Heading } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HookableComponent } from 'react-hookable-component';
import useScreenType from "react-screentype-hook";
import {EmergencyContactsBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor, lightGreen, backgroundGradientColor} from '../constants/colors';


const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class EmergencyContact extends HookableComponent {

  constructor() {
    super();
    //this.callDone = false;
    this.state = {
      loading: false
    };
  }

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  deleteRow = async guestID => {
    const {deleteEmergencyContact, getAllEmergencyContacts} = this.props;
    // console.log('@deleteRow');
    // console.log(guestID);
    this.setState({loading: true});
    await deleteEmergencyContact(guestID);
    await getAllEmergencyContacts();
    this.setState({loading: false});
  };

  componentDidMount = async () => {
    //this.callDone = true;
    const {getAllEmergencyContacts} = this.props;
    this.setState({loading: true});
    await getAllEmergencyContacts();
    this.setState({loading: false});
  };

  render() {
    let {allEmmCon, classes} = this.props;
    let {loading} = this.state;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const screenType = useScreenType();
    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center', borderRadius: 10}}
          src={EmergencyContactsBanner}
          alt={'EmergencyContactsBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <HStack space={2} justifyContent="flex-end">
            <Pressable
              style={{borderRadius: 5, justifyContent: 'center', alignItems: 'center', background: backgroundGradientColor}}
              onPress={() => {this.props.history.push({pathname: 'add-edit-emergency-contact', state: {data: null}});}}
              underlayColor={underlayColor}>
              <HStack space={2} justifyContent="space-between" alignItems="center" p="2">
                <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="md" color={"white"}/>}/>
                <Heading _dark={{
                  color: "white"
                }} color="white" fontWeight="medium" size="xs">
                  Add Emergency Contact Details
                </Heading>
              </HStack>
            </Pressable>
          </HStack>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack>
              {/* <Text style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  width: '6%',
                  padding: 7,
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  textAlign: 'center'
              }}>S.No.</Text> */}
              <VStack w='30%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: "90%",
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '30%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Contact Name
              </Text>
              </VStack>
              <VStack w='29%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: "90%",
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '29%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Contact Phone
              </Text>
              </VStack>
              <VStack w='29%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: "90%",
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '29%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Notes
              </Text>
              </VStack>
              <VStack w='12%' padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: "90%",
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '6%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Action
              </Text>
            </VStack>
            </HStack>
            <FlatList
              style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
              data={allEmmCon}
              renderItem={({item, index}) => (
                <Pressable
                  style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                  onPress={() => {this.props.history.push({pathname: 'add-edit-emergency-contact', state: {data: item}})}}
                  underlayColor={underlayColor}>
                  {/* <Box flex="1"> */}
                    <HStack>
                      {/* <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: '6%',
                          padding: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                      }}>{index+1}</Text> */}
                      <VStack w='30%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: "90%",
                            // padding: 7,
                            // width: '30%',
                            textTransform: 'capitalize',
                            // borderRightColor: borderColor,
                            // borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {item?.contacT_NAME}
                      </Text>
                      </VStack>
                      <VStack w='29%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: "90%",
                            // padding: 7,
                            // width: '29%',
                            textTransform: 'capitalize',
                            // borderRightColor: borderColor,
                            // borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {item.contacT_PHONE}
                      </Text>
                      </VStack>
                      <VStack w='29%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: "90%",
                            // padding: 7,
                            // width: '29%',
                            textTransform: 'capitalize',
                            // borderRightColor: borderColor,
                            // borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {(item.notes)?item.notes:'-'}
                      </Text>
                      </VStack>
                      <VStack w='12%' alignItems={'center'} justifyContent={'center'}>
                        <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteRow(item.contactID)}} />
                      </VStack>
                    </HStack>
                  {/* </Box> */}
                </Pressable>
              )}
            />
          </VStack>
        </div>
        {/* <Fab onPress={() => {this.props.history.push({pathname: 'add-edit-emergency-contact', state: {data: null}});}}
        renderInPortal={false} shadow={2} size="sm" icon={<Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color="white"/>} />} /> */}
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

EmergencyContact.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EmergencyContact);
