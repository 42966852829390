
// export const materialColor = '#097969' //button colors // ABDI GREEN
export const appBarColor = '#FFFFFF' //top bar background color // WHITE
export const drawerColor = '#097969' //nav menu background color // ABDI GREEN
export const mainMenuBackgroundColor = '#FFFFFF' //nav menu icon color // WHITE
export const mainMenuHeaderTextColor = '#FFFFFF' //nav menu button main line color // WHITE
export const mainMenuHeaderSubTextColor = '#FFFFFF' //nav menu button 2nd line color // WHITE
export const hoverBackgroundColor = '#023020' //nav menu selection fill color //BLACK
export const hoveredForegroundTextColor = '#023020' //hovering over nav menu fill color // DARK GREEN
export const borderBottomColor = '#000' //table hr color // BLACK
export const searchBarBorderColor = '#FFFFFF' //table column header background color (only in auth vendors??) // WHITE
export const listMainTextColor = '#000' //table text color // BLACK
export const switchButtonColor = '#097969' //switches ON position fill color // ABDI GREEN

// export const primaryColor = '#FFA500' // used as backgroundColor of appBar in vendor screen.
export const underlayColor = '#00FF00' // Used in all Pressable commponents as underlayColor.

export const primaryColor = '#0047CC';
export const materialColor = '#006400';
export const splashColor = '#fff';

export const brandColor = '#0B7645';
export const brandColorBelow = '#021A02';
export const lightGreen = '#BDE2C7';
export const borderColor = '#57855E';
export const titleTextColor = '#57855E';
export const subTitleTextColor = '#57855E';
export const arrowColor = '#02AC2F';
export const loginButtonColor = '#02AC2F';
export const headerColor = '#BDE2C7';
export const deleteColor = '#EB3737';
export const headerTitleColor = '#58595B'; // header title color which shows on every screens
export const backgroundGradientColor = 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'; //background gradient or save button color in all the screens
export const drawerMenuBackgroundColor = 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)'; // drawer menu background color
export const cancelButtonColor = '#ED1C24'; //cancel or close button color
export const footerTextColor = '#000000'; //footer text color
