import React from 'react';
import { Box, Heading, VStack, HStack, Spinner, Flex, FlatList, Text, Pressable } from "native-base";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";
import SearchBar from "material-ui-search-bar";
import { HookableComponent } from 'react-hookable-component';
import useScreenType from "react-screentype-hook";
import {RecentVisitorBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor} from '../constants/colors';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};


class RecentVisitorsScreen extends HookableComponent {
  constructor() {
    super();
    this.arrayholder = [];
    this.state = {
      allData: [],
      value: '',
      loading: false
    };
  }

  async componentDidMount() {
    const {getRecentVisitors} = this.props;
    this.setState({loading: true});
    await getRecentVisitors();
    this.setState({allData: this.props.recentVisitors});
    this.arrayholder = this.props.recentVisitors;
    this.setState({loading: false});
  }

  search = text => {
    const newData = this.arrayholder.filter(item => {
      const itemData = `${item.guest_Name.toLowerCase()}`;
      const textData = text.toLowerCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      allData: newData,
    });
  }

  render() {
    const {classes} = this.props;
    const {loading, allData} = this.state;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const screenType = useScreenType();

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center', borderRadius: 10}}
          src={RecentVisitorBanner}
          alt={'RecentVisitorBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <HStack space={2} justifyContent="space-between">
            <SearchBar
              style={{width: "40%", borderRadius: 5, borderWidth:1, borderColor: searchBarBorderColor}}
              value={this.state.value}
              //onChange={(newValue) => this.setState({ value: newValue })}
              onChange={(newValue) => this.search(newValue)}
              onCancelSearch={() => this.search('')}
              //onRequestSearch={() => this.search(this.state.value)}
            />
          </HStack>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack>
              {/* <Text style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  width: '6%',
                  padding: 7,
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  textAlign: 'center'
              }}>S.No.</Text> */}
              <VStack w='34%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: "90%",
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '34%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Visitor Name
              </Text>
              </VStack>
              <VStack w='30%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: "90%",
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '30%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Entry Time
              </Text>
              </VStack>
              <VStack w='36%' padding={2}>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    // padding: 7,
                    // width: '36%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Tag
              </Text>
              </VStack>
            </HStack>
            <FlatList
              style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
              data={allData}
              renderItem={({item, index}) => (
                <Pressable
                  style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                  onPress={() => {this.props.history.push({pathname: 'recent-visitors-details', state: {data: item}})}}
                  underlayColor={underlayColor}>
                  {/* <Box flex="1"> */}
                    <HStack>
                      {/* <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: '6%',
                          padding: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                      }}>{index+1}</Text> */}
                      <VStack w='34%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: "90%",
                            // padding: 7,
                            // width: '34%',
                            textTransform: 'capitalize',
                            // borderRightColor: borderColor,
                            // borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {item?.guest_Name}
                      </Text>
                      </VStack>
                      <VStack w='30%' borderRightColor={borderColor} borderRightWidth={1} padding={2}>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: "90%",
                            // padding: 7,
                            // width: '30%',
                            textTransform: 'capitalize',
                            // borderRightColor: borderColor,
                            // borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>{moment(item.entry_time).format("MM-DD-YYYY hh:mm:ss")}
                      </Text>
                      </VStack>
                      <VStack w='36%' padding={2}>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: "90%",
                            // padding: 7,
                            // width: '36%',
                            textTransform: 'capitalize',
                            // borderRightColor: borderColor,
                            // borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {(item?.tag_one) ? item?.tag_one : "-"}
                      </Text>
                      </VStack>
                    </HStack>
                  {/* </Box> */}
                </Pressable>
              )}
            />

            <Heading _dark={{
              color: "warmGray.200"
            }} color="coolGray.600" fontWeight="medium" size="sm" m={5} alignSelf='center'>
              This information is read-only
            </Heading>
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}
RecentVisitorsScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RecentVisitorsScreen);
