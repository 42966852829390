import React from 'react';
import PropTypes from 'prop-types';
import { Heading, VStack, FormControl, Flex, Spinner, Button, HStack, Icon, Pressable } from "native-base";
import { withStyles } from '@material-ui/core/styles';
import {RecentVisitorBanner} from '../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HookableComponent } from 'react-hookable-component';
import useScreenType from "react-screentype-hook";
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor, backgroundGradientColor} from '../constants/colors';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class RecentVisitorsDetails extends HookableComponent {
  constructor() {
    super();
    this.state = {
      entry_time: '',
      guest_Name: '',
      companyName: '',
      tag_one: '',
      color: '',
      type: '',
      gateName: '',
      currentGuard: '',
      //loading: false
    };
  }

  componentDidMount() {
    //const {navigation} = this.props;
    const allData = this.props.history.location.state.data;
    // console.log('@RecentVisitorsDetails.');
    // console.log(allData);

    const thisComp = this;
    if (allData) {
      thisComp.setState({
        entry_time: allData.entry_time,
        guest_Name: allData.guest_Name,
        companyName: allData.companyName,
        tag_one: allData.tag_one,
        color: allData.color,
        type: allData.type,
        gateName: allData.gateName,
        currentGuard: allData.currentGuard,
      });
    }
  }

  render() {
    const {
      entry_time,
      guest_Name,
      companyName,
      tag_one,
      color,
      type,
      gateName,
      currentGuard,
      loading
    } = this.state;
    const { classes } = this.props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const screenType = useScreenType();

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center', borderRadius: 10}}
          src={RecentVisitorBanner}
          alt={'RecentVisitorBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>

            <HStack space={5} m={(screenType.isMobile) ? 1 : 3} w='50%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label style={{color: titleTextColor}}>
                  Entry Time
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {entry_time}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label style={{color: titleTextColor}}>
                  Guest Name
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {guest_Name}
                </Heading>
              </FormControl>
            </HStack>
            <HStack space={5} m={(screenType.isMobile) ? 1 : 3} w='50%' alignItems='center' justifyContent='space-between'>
              <FormControl>
                <FormControl.Label>
                  Company Name
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {companyName}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Tag One
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {tag_one}
                </Heading>
              </FormControl>
            </HStack>
            <HStack space={5} m={(screenType.isMobile) ? 1 : 3} w='50%' alignItems='center' justifyContent='space-between'>
              <FormControl>
                <FormControl.Label>
                  Color
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {color}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Type
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {type}
                </Heading>
              </FormControl>
            </HStack>
            <HStack space={5} m={(screenType.isMobile) ? 1 : 3} w='50%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label>
                  Gate Name
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {gateName}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Current Guard
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {currentGuard}
                </Heading>
              </FormControl>
            </HStack>
          </VStack>
          <VStack space={3} m={6} w='95%' alignItems='flex-end' mb={0} mt={5}>
            <Pressable
              style={{borderRadius: 5, width: '20%', justifyContent: 'center', alignItems: 'center', background: backgroundGradientColor}}
              onPress={()=>{this.props.history.goBack()}}
              disabled={loading}
              underlayColor={underlayColor}>
              <HStack space={3} justifyContent="space-between" alignItems="center" p="3">
                {/* <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/> */}
                <Heading _dark={{
                  color: "white"
                }} color="white" fontWeight="medium" size="sm">
                  Back
                </Heading>
              </HStack>
            </Pressable>
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg"/>
          </div>
        )}
      </div>
    );
  }
}
RecentVisitorsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RecentVisitorsDetails);
